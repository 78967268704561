<template>
  <div>
    <div class="__header">
      <div class="header__box-logo">
        <img src="@/assets/logo/logo-newave.png" />
      </div>
      <div class="text__header">Portal Newave</div>
    </div>
    <recompra-preco-unico
    buttonStyle="btn-action-primary-newave"
      v-if="TIPO === 'PRECO_UNICO' && STATUS === 'EM_PREENCHIMENTO'"
      :contratoPrincipal="contratoPrincipal"
    />
    <recompra-precos-diferentes
    buttonStyle="btn-action-primary-newave"
      v-if="TIPO === 'PRECOS_DIFERENTES' && STATUS === 'EM_PREENCHIMENTO'"
      :contratoPrincipal="contratoPrincipal"
    />
    <proposta-recompra-aprovada
    buttonStyle="btn-action-primary-newave"
      v-if="STATUS === 'APROVADA'"
      :contratoPrincipal="contratoPrincipal"
      :demonstrativo="demonstrativo"
    />
    <proposta-recompra-contestada v-if="STATUS === 'REPROVADA'" />
  </div>
</template>
<script>
import RecompraPrecosDiferentes from "@/pages/hot-site/recompra/HotSiteRecompraPrecosDiferentes.vue";
import PropostaRecompraAprovada from "@/pages/hot-site/recompra/PropostaRecompraAprovada.vue";
import PropostaRecompraContestada from "@/pages/hot-site/recompra/PropostaRecompraContestada.vue";
import RecompraPrecoUnico from "@/pages/hot-site/recompra/RecompraPrecoUnico.vue";

export default {
  components: {
    RecompraPrecoUnico,
    RecompraPrecosDiferentes,
    PropostaRecompraAprovada,
    PropostaRecompraContestada,
  },
  props: {
    contratoPrincipal: Object,
    demonstrativo: Object,
  },
  computed: {
    TIPO() {
      return this.demonstrativo?.recompraDemonstrativo?.tipo;
    },
    STATUS() {
      return this.demonstrativo?.recompraDemonstrativo?.status;
    },
  },
  data() {
    return {
      loadingPage: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.__header {
  height: 60px;
  width: 100%;
  display: flex;
  background: #505050;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #ffffff;

  img {
    width: 45px;
  }
}

.header__box-logo {
  padding: 0 15px 0 15px;
}
</style>
