<template>
  <div>
    <div class="box-cards">
      <div class="cards-line">
        <div class="_card">
          <div class="_card_title">Volume a Faturar</div>
          <div class="_card_body">
            <div class="_card_logo">
              <Icon name="apurado" color="#0B2B40"></Icon>
            </div>
            <div class="_card_value">
              {{ contrato.calculoSumarizado.volumeLiquido | volume }} MWh
            </div>
          </div>
        </div>
        <div class="_card">
          <div class="_card_title">Preço sem ICMS (R$/MWh)</div>
          <div class="_card_body">
            <div class="_card_logo">
              <Icon name="preco" color="#0B2B40"></Icon>
            </div>
            <div class="_card_value">
              R$ {{ contrato.calculoSumarizado.precoSemIcms | moeda }}
            </div>
          </div>
        </div>
      </div>
      <div class="cards-line">
        <div class="_card">
          <div class="_card_title">Consumo Apurado</div>
          <div class="_card_body">
            <div class="_card_logo">
              <Icon name="apurado" color="#0B2B40"></Icon>
            </div>
            <div class="_card_value">
              {{ contrato.calculoSumarizado.volumeBruto | volume }} MWh
            </div>
          </div>
        </div>

        <div class="_card">
          <div class="_card_title">Montante SCDE</div>
          <div class="_card_body">
            <div class="_card_logo">
              <Icon name="scde" color="#0B2B40"></Icon>
            </div>
            <div class="_card_value">
              {{ contrato.calculoSumarizado.montanteScde | volume }} MWh
            </div>
          </div>
        </div>
      </div>
      <div class="cards-line">
        <div class="_card">
          <div class="_card_title">Volume Contratado</div>
          <div class="_card_body">
            <div class="_card_logo">
              <Icon name="contratado" color="#0B2B40"></Icon>
            </div>
            <div class="_card_value">
              {{
                contrato.flexibilidade &&
                contrato.flexibilidade.quantidadeContratada | volume
              }}
              MWh
            </div>
          </div>
        </div>
        <div class="_card">
          <div class="_card_title">Tipo de Energia</div>
          <div class="_card_body">
            <div class="_card_logo">
              <Icon name="energia" color="#0B2B40"></Icon>
            </div>
            <div class="_card_value">
              <span style="text-transform: uppercase">{{
                contrato.tipoEnergia.descricao
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="contrato && contrato.classificacao !== 'FULL FLEX'"
      class="box-take"
    >
      <div class="_card_title">Take Mínimo e Máximo</div>
      <div class="wrapper-takes">
        <div class="box-take-min">
          <div class="take-description">Take Mínimo (MWh)</div>
          <div class="take-value">
            {{
              contrato.flexibilidade &&
              contrato.flexibilidade.limiteMinimo | volume
            }}
          </div>
        </div>
        <div class="box-take-max">
          <div class="take-description">Take Máximo (MWh)</div>
          <div class="take-value">
            {{
              contrato.flexibilidade &&
              contrato.flexibilidade.limiteMaximo | volume
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "@/pages/hot-site/components/MutableIcons.vue";

export default {
  props: ["contrato"],
  components: {
    Icon,
  },
};
</script>
<style lang="scss" scoped>
.box-cards {
  margin-top: 10px;
}
.cards-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  @include tablet {
    flex-direction: column;
    margin-bottom: 0px;
  }
}
._card {
  background: #ffffff;
  height: 150px;
  width: calc(50% - 13px);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  @include tablet {
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
  }
}
.box-take {
  width: 100%;
  height: 140px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: #ffffff;
}
._card_title {
  border-bottom: solid 1px #ebeff2;
  padding: 10px;
  padding-left: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #192a3e;
}
._card_body {
  display: flex;
  justify-content: space-between;
  height: 107px;
  align-items: center;
}
._card_logo {
  padding: 0 20px 0 20px;
  color: #0b2b40;
  fill: #0b2b40;
}
._card_value {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #0b2b40;
  // @include mobile {
  //   font-size: 20px;
  //   text-align: center;
  // }
}
.wrapper-takes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 98px;
  color: #0b2b40;
  // @include mobile {
  //  flex-direction: column;
  //  min-width: 330px;
  // }
}
.box-take-min {
  width: 50%;
  display: flex;
  border-right: dashed 1px #eee;
  height: 100%;
  align-items: center;
  color: #0b2b40;
  //@include mobile {
  //  width: 100%;
  //}
}
.box-take-max {
  width: 50%;
  display: flex;
  height: 100%;
  align-items: center;
  //@include mobile {
  //  width: 100%;
  //}
}
.take-description {
  padding-left: 15px;
  width: 50%;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #929292;
  //@include mobile {
  //  font-size: 14px;
  //}
}
.take-value {
  width: 50%;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #0b2b40;
  //@include mobile {
  // text-align: center;
  //}
}
</style>
